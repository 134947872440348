
@import 'src/assets/styles/constants';
@import 'src/assets/styles/colors';

.header-bar {
  &-pinned {
    .navbar {
      border-bottom-color: $pengine-grey-dark-dark;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
  }

  .navbar {
    height: $header-bar-nav-buttons-height;
    min-height: inherit;
    align-items: stretch;
    display: flex;

    .navbar-brand {
      min-height: initial;
      display: none;
    }

    .navbar-menu {
      padding: 0;
      align-items: stretch;
      -webkit-box-align: stretch;
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      -webkit-box-flex: 1;

      .navbar-start {
        justify-content: flex-start;
        margin-right: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-box-align: stretch;

        .button {
          border: none !important;
          background-color: transparent;
          padding-right: 0px;
          height: $header-bar-nav-buttons-height;

          &:hover, &:focus {
            background-color: transparent;
          }

          &:hover {
            color: $pengine-blue-dark;
          }

          span {
            display: flex;
            align-items: center;

            .exi {
              display: inline-block;
              width: 24px;
              height: 24px;
            }

            .button-icon, .button-label {
              float: left;
            }

            .button-label {
              padding-left: 8px;
              font-family: $text-font;
              font-size: 14px;
            }
          }

          &.group:after {
            content: "";
            background: $pengine-grey-dark;
            position: absolute;
            bottom: 6px;
            left: 0;
            height: 75%;
            width: 1px;
          }

          &.loading .exi-small-spinner-unmasked {
            color: $pengine-blue;
          }
        }

        .dropdown {
          .dropdown-menu {
            .dropdown-content {
              width: fit-content;

              .dropdown-item {
                height: 35px;
                padding: 0px;

                &:hover { // This also applies for non button items (eg. slider)
                  color: $pengine-blue-dark;
                }

                .button {
                  padding: 8px 16px 2px 16px;
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;

                  &.loading .exi-small-spinner-unmasked {
                    color: $pengine-blue;
                  }
                }

                .switch-field {
                  padding: 8px 16px 2px 16px;

                  .switch-label {
                    padding-right: 8px;
                  }

                  .switch-slider {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .switch-slider-flex {
                      display: flex;
                      pointer-events: none;
                    }
                  }

                  .switch {
                    margin-right: -8px;
                  }
                }
              }
            }
          }
        }
      }

      .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-box-align: stretch;

        .navbar-item {
          padding-top: 0;
          padding-bottom: 0;
          height: calc(#{$header-bar-nav-buttons-height} - 2px);
        }
      }
    }
  }

  .pin-button {
    padding-top: 0;
    padding-bottom: 0;
    height: $header-bar-nav-buttons-height;
    border: none;
  }

  .divider {
    display: block;
    height: 90%;
    margin-left: 12px;
    background-color: $pengine-grey;
    width: 1px;
  }
}
